import React, {useState, useEffect} from 'react';
import {USER_PROJECT, USER_ROLE, USER_ID, AUTH_TOKEN} from "../../constants/Auth";
import Select from "react-select";
import PreTableWithPopups from "../../components/tables/PreTableWithPopups";
import CreateNewItemBtn from "../../components/buttons/CreateNewItemBtn";
import filterByProject from "../../helpers/filter-by-project";
import CustomTooltip from "../../components/CustomTooltip";
import Loader from "../../components/loaders/Loader";
import Modal from "../../components/modal";
import {modifyPostbackData} from "../../helpers/modify-postbacks-data";
import PostbackPopup from "../../components/popups/PostbackPopupWithoutConfig.js";
import {getPostbacks, getTemplates, deletePostback} from "../../services/postbacks";
import Notiflix from "notiflix";
import ClearFilters from "../../components/buttons/ClearFilters";
import {MOBILE_FILTER_TYPE as service} from "../../constants";

function PostbackSetup() {

    const role = localStorage.getItem(USER_ROLE);
    const projectName = localStorage.getItem(USER_PROJECT);
    const token = localStorage.getItem(AUTH_TOKEN);
    const [accountDetails, setAccountDetails] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [isError, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);

    const [columnDefs, setColumnDefs] = useState([]);
    const [configData, setConfigData] = useState([]);
    const [addPopupStatus, setAddPopupStatus] = useState(false);
    const [addPopupWithConfigData, setAddPopupWithConfigData] = useState(false);
    const [initialValues, setInitialValues] = useState({});
    const [isPopupType, setPopupType] = useState('updating');
    const [remarksList, setRemarksList] = useState([]);
    const [isOpen, setIsOpen] = useState(false)
    const [postbacksData, setPostbacksData] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [currentTemplate, setCurrentTemplate] = useState(null);

    const [usersList, setUsersList] = useState([])
    const [restData, setRestData] = useState(null)
    const [anAccounts, setAnAccounts] = useState([])
    const [selectedANAccount, setSelectedANAccount] = useState(null)

    const [accountName, setAccountName] = useState("")
    const [accountId, setAccountId] = useState("")
    const [services, setServices] = useState([])
    const [isTriggerUpdate, setIsTriggerUpdate] = useState(false)


    const [selectedUser, setSelectedUser] = useState({
        value: localStorage.getItem("id"),
        label: localStorage.getItem("name"),
    })

    const selectStyles = {
        container: (baseStyles) => ({
            ...baseStyles,
            maxWidth: "180px",
            width: "100%",
            margin: "0 20px 0 0",
        }),
        control: (baseStyles, state) => ({
            ...baseStyles,
            fontSize: "14px",
            lineHeight: "1.2",
            minHeight: "26px",
            border: "none",
            borderRadius: "8px",
            "&:hover": {
                cursor: "pointer",
            },
        }),
        placeholder: (baseStyles) => ({
            ...baseStyles,
            fontWeight: 500,
            color: "#1A3353",
        }),
        menu: (baseStyles, state) => ({
            ...baseStyles,
            zIndex: "6",
            "&:hover": {
                cursor: "pointer",
            },
        }),
        option: (baseStyles, state) => ({
            ...baseStyles,
            fontSize: "14px",
            lineHeight: "1.2",
            "&:hover": {
                cursor: "pointer",
            },
        }),
    }



    useEffect(() => {
        if (role !== 'admin') return
        setLoading(true);

        // initial fetch
        fetch(`${localStorage.getItem("apiUrl")}api/user/${selectedUser.value}/services/`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
        })
            .then(res => {
                if (res.ok) {
                    return res.json()
                }
                return Promise.reject(res)
            })
            .then(res => {
                setUsersList([...res["user_managers_accounts"]])
                setRestData(res)
                setLoading(false)
            })
            .catch(res => {
                setLoading(false)
            })
    }, []);

    useEffect(() => {

        async function update() {
            if (isTriggerUpdate) {
              await getAllPostbacks()
                return setIsTriggerUpdate(false)
            }
        }

        update()

    }, [isTriggerUpdate]);


    useEffect(() => {
        setSelectedUser({
            value: localStorage.getItem("id"),
            label: localStorage.getItem("name"),
        })
        getAllTemplates();


    }, []);

    useEffect(() => {
        getAccountDetails();
    }, [postbacksData])

    useEffect(() => {

        let account_name = ''
        if (anAccounts.length > 0 && accountId) {
            let findedName = anAccounts.find(a => a.value === accountId)
            if (findedName) {
                account_name = findedName
            }
        }

        if (currentTemplate) {
            setPopupType('create')
            setInitialValues({
                ...currentTemplate,
                postback_name: currentTemplate.name,
                et: currentTemplate.name === "conversion" ? "" : currentTemplate.name,
                voluum_tracking_domain: "",
                start_click_date: currentTemplate.name === 'conversion' ? "2024-01-01" : "2020-01-01",
                template_id: currentTemplate.id,
                service_credential_id: accountId,
                postback_status: "deactivated",
                account_name: account_name
            });
        }
    }, [currentTemplate]);

    useEffect(() => {
        if (role !== 'admin') {
            fetch(`${localStorage.getItem("apiUrl")}api/user/${selectedUser.value}/services`, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            })
                .then(res => {
                    if (res.ok) {
                        return res.json()
                    }
                    return Promise.reject(res)
                })
                .then(res => {

                    if (res.user_services.length > 0) {


                        let temp = []
                        let services = []

                        for (let service of res.user_services) {
                            temp.push({
                                value: service.id,
                                label: service.name
                            })
                            services.push(service.id)
                        }

                        temp.unshift({
                            value: "all",
                            label: "All",
                        })

                        if (temp.length > 0) {
                            setAnAccounts(temp)
                        }

                        if (services.length > 0) {
                            setAccountId(services[0])
                            setServices(services)
                        }

                        setRestData(res)
                    } else {
                        setError(true)
                        setErrorMessage("The user doesn't have any service")
                    }


                })
                .catch(e => {
                    setLoading(false);
                })
        }
    }, []);

    useEffect(() => {
        if (services.length > 0) {
            getAllPostbacks()
        }
    }, [services]);


    const handleDeletePostback = async (id) => {
        setLoading(true);

        const delResult = await deletePostback(id, token);

        if (delResult && delResult.message) {
            Notiflix.Notify.success(delResult.message)

        }
        setIsTriggerUpdate(true)

        setLoading(false);
    }

    const getAllPostbacks = async () => {
        setLoading(true);

            let params = {
                "service_credential_id": [...services]
            }

        await getPostbacks(params, token)
            .then(res => {
            if (res) {
                return res.json()
            }
        })
            .then(res => {
                if (res.data.length > 0) {

                    let parsedData = parsingRules(res.data);
                    setPostbacksData(parsedData);
                } else {
                    setPostbacksData([])
                    setAccountDetails([])
                    setErrorMessage("The user doesn't have any service")
                }
            }
            )
            .catch(e => console.error(e))

        setLoading(false);
    }


    const getAllTemplates = async () => {
        setLoading(true);

        await getTemplates(token)
            .then(res => {
                if (res) {
                    return res.json()
                }
            })
            .then(res => {
                if (res.data.length > 0) {
                    setTemplates(res.data);
                }
            })
            .catch(e => console.error(e))

        setLoading(false);
    }



    const handleChangeSelectedUser = (user) => {
        setLoading(true);
        setPostbacksData([])
        setSelectedANAccount(null)

        // fetch user services
        fetch(`${localStorage.getItem("apiUrl")}api/user/${user.value}/services`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
        })
            .then(res => {
                if (res.ok) {
                    return res.json()
                }
                return Promise.reject(res)
            })
            .then(res => {
            setSelectedUser({
                value: res.id,
                label: res.name
            });

            if (res.user_services.length > 0) {
                let services = [];
                let temp = [];

                for (let service of res.user_services) {
                    services.push(Number(service.id));
                    temp.push({
                        value: service.id,
                        label: service.name
                    });
                }

                if (temp.length > 0) {
                    setAnAccounts(temp);
                }

                if (services.length > 0) {
                    setAccountId(services[0]);
                    setServices(services);
                }
            } else {
                // Clear the state for anAccounts and selectedANAccount selects
                setAnAccounts([]);
                setSelectedANAccount(null);

                setPostbacksData([]);
                setAccountDetails([]);
                setErrorMessage("The user doesn't have any service");
            }

            setLoading(false);
        })
            .catch(res => {
                setLoading(false);
            });
    }

    const clearUserFilter = () => {
        setServices([])
        setPostbacksData([])
        setSelectedANAccount(null)

        setSelectedUser({
            value: localStorage.getItem("id"),
            label: localStorage.getItem("name"),
        })

        setAnAccounts([])
        setIsTriggerUpdate(true)
    }

    const handleChangeANAccount = (option) => {
        if (option.value === "all") {
            setAccountName("All");
            setAccountId("all");
            setSelectedANAccount(null)

            fetch(`${localStorage.getItem("apiUrl")}api/user/${selectedUser.value}/services`, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            })
                .then(res => {
                    if (res.ok) {
                        return res.json()
                    }
                    return Promise.reject(res)
                })
                .then(res => {

                    if (res.user_services.length > 0) {


                        let temp = []
                        let services = []

                        temp.push({
                            value: "all",
                            label: "All",
                        })

                        for (let service of res.user_services) {
                            temp.push({
                                value: service.id,
                                label: service.name
                            })
                            services.push(service.id)
                        }

                        if (temp.length > 0) {
                            setAnAccounts(temp)
                        }

                        if (services.length > 0) {
                            setAccountId(services[0])
                            setServices(services)
                        }

                        setRestData(res)
                    } else {
                        setError(true)
                        setErrorMessage("The user doesn't have any service")
                    }


                })
                .catch(e => {
                    setLoading(false);
                }
                )
        } else {
            setSelectedANAccount(option);

            const accountID = option.value;
            const service_acc_name = option.label;

            setAccountName(service_acc_name);
            setAccountId(accountID);

            setServices([accountID])
        }
    }

    const parsingRules = (data) => {
        if (data.length > 0) {
            for (let postback of data) {

                if (postback.account_name && anAccounts.length > 0) {
                    let findedAccId = anAccounts.find(a => a.label === postback.account_name )
                       if (findedAccId) {
                            postback.service_credential_id = findedAccId.value
                       }
                }



                let clickStartDate = ""
                let domain = ""

                let ruleString = JSON.stringify(postback.rule);
                let etIndex = ruleString.indexOf("&et=");
                let etStr = ruleString.slice(etIndex);


                let clickStartDateArr = ruleString.match(/(19|20)\d\d-((0[1-9]|1[012])-(0[1-9]|[12]\d)|(0[13-9]|1[012])-30|(0[13578]|1[02])-31)/)
                if (clickStartDateArr && clickStartDateArr.length) {
                    clickStartDate = clickStartDateArr[0];
                }

                let domainStrArr = ruleString.match(/\b((?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z]{2,})\b/gi);
                
                if (domainStrArr && domainStrArr.length) {
                    domain = domainStrArr[0];
                }

                if (clickStartDate) {
                    postback.start_click_date = clickStartDate;
                } else {
                    postback.start_click_date = ""
                }

                if (domain) {
                    postback.voluum_tracking_domain = domain;
                } else {
                    postback.voluum_tracking_domain = "";
                }

                delete postback.rule
            }
        }
        return data
    }

    const modifiedColumnsData = (columns) => columns.map(el => {
        let columnDef = {
            field: el,
            headerName: el.replaceAll('_', ' ').replace(/([a-z])([A-Z])/g, '$1 $2'),
            filter: true,
            wrapText: true,
            tooltipField: el,
            tooltipComponent: CustomTooltip,
        };

        if (el === 'id') {
            columnDef = {
                ...columnDef,
                minWidth: 20,
                maxWidth: 80,
                hide: true
            }
        } else if (el === 'postback_name') {
            columnDef = {
                ...columnDef,
                minWidth: 150,
                maxWidth: 220,
            }
        } else if (el === 'delete') {
            columnDef = {
                ...columnDef,
                hide: true,
            }

        } else if (el === 'postback_template_id') {
            columnDef = {
                ...columnDef,
                hide: true,
            }

        } else if (el === 'voluum_tracking_domain') {
            columnDef = {
                ...columnDef,
                minWidth: 220,
                maxWidth: 240,
            }
        } else if (el === 'start_click_date') {
            columnDef = {
                ...columnDef,
                minWidth: 140,
                maxWidth: 180,
            }
        } else if (el === 'et') {
            columnDef = {
                ...columnDef,
                minWidth: 70,
                maxWidth: 100,
                hide: false,
            }
        } else if (el === 'template_id') {
            columnDef = {
                ...columnDef,
                minWidth: 70,
                maxWidth: 100,
                hide: true,
            }
        } else if (el === 'service_credential_id') {
            columnDef = {
                ...columnDef,
                minWidth: 70,
                maxWidth: 100,
                hide: true,
            }
        } else if (el === 'account_name') {
            columnDef = {
                ...columnDef,
                minWidth: 190,
                maxWidth: 250,
            }
        } else if (el === 'postback_status') {
            columnDef = {
                ...columnDef,
                minWidth: 180,
                maxWidth: 180,
                cellRenderer: (params) => {
                    const {value} = params;
                    return (
                        <span className={`status-cell${value === "active" ? ' active-cell' : ' deactivated-cell'}`}>
                            {value === "active" ? 'active' : 'deactivated'}
                        </span>
                    )
                }
            }
        } else {
            columnDef = {
                ...columnDef,
                suppressSizeToFit: true,
                minWidth: 80,
                maxWidth: 140
            }
        }
        return columnDef;
    })

    const createInitialValue = (data) => data.reduce((allEl, el) => {
            return {
                ...allEl,
                [el]: projectName,
            };
    }, {});

    const openCreatePopup = () => {
        setIsOpen(true)
    }

    const getAccountDetails = () => {
        if (postbacksData.length > 0) {
            setLoading(true);
            try {
                const modifyData = modifyPostbackData(postbacksData);
                    setAccountDetails(modifyData);
                    setConfigData(modifyData);
                if (modifyData.length > 0) {
                    let keyArr = Object.keys(modifyData[0])
                    let countedNames = createInitialValue(keyArr);
                    setInitialValues(countedNames)
                }
                setLoading(false);

            } catch (error) {
                setLoading(false);
                console.error('Error!', error);
            }
        }
    }

    const openModal = () => {
        setIsOpen(true)
    }

    const closeModal = () => {
        setIsOpen(false)
        setCurrentTemplate(null)
        setPopupType(null);
    }

    const closePopup = () => {
        setIsOpen(false)
        setAddPopupStatus(false);
        setPopupType(null);
    }

    if (isError) {
        return (
            <div className="loading-wrap">
                { errorMessage ? errorMessage : "There were problems receiving data from the server, please try again later."}

            </div>
        )
    }



    return (
        <div className='yieldkit-accounts-page-container'>

            {
                isLoading && <div className='loading-wrap'><Loader /></div>
            }

            {
                ((templates.length > 0 && anAccounts.length > 0) || (role === "admin" && anAccounts.length > 0)) && <CreateNewItemBtn styles={selectStyles} title={"+ Add new postback"} onClick={openCreatePopup}/>
            }

            {(remarksList.includes(projectName) || role === 'admin') ?
                    (<div style={{
                        display: "flex",
                        margin: "0 0 10px",
                        gap: "20px"
                    }}>
                        <Select
                            className={""}
                            styles={selectStyles}
                            options={usersList.reduce((acc, item) => {
                                acc.push({value: item.id, label: item.name})
                                return acc
                            }, [])}
                            isSearchable={true}
                            defaultValue={{
                                value: localStorage.getItem("id"),
                                label: localStorage.getItem("name"),
                            }}
                            name={"user"}
                            placeholder={"User"}
                            components={{
                                IndicatorSeparator: () => null
                            }}
                            onChange={(option) => handleChangeSelectedUser(option)}
                            value={selectedUser}
                        />

                        <Select
                            className={""}
                            styles={selectStyles}
                            options={anAccounts}
                            isSearchable={true}
                            defaultValue={selectedANAccount}
                            name={"affiliate_network_account"}
                            placeholder={"AN Account"}
                            value={selectedANAccount}
                            components={{
                                IndicatorSeparator: () => null
                            }}
                            onChange={(option) => handleChangeANAccount(option)}
                        />

                        <ClearFilters
                            text={"Clear"}
                            clearFilters={clearUserFilter}
                        />

                    </div>) : (
                    <div style={{
                        display: "flex",
                        margin: "0 0 10px",
                        gap: "20px"
                    }}>
                        <Select
                            className={""}
                            styles={selectStyles}
                            options={anAccounts}
                            isSearchable={true}
                            defaultValue={selectedANAccount}
                            name={"affiliate_network_account"}
                            placeholder={"AN Account"}
                            value={selectedANAccount}
                            components={{
                                IndicatorSeparator: () => null
                            }}
                            onChange={(option) => handleChangeANAccount(option)}
                        />
                    </div>
                )
            }
            {
                accountDetails.length > 0 && <PreTableWithPopups
                    rowData={accountDetails}
                    columnDefs={columnDefs}
                    setColumnDefs={setColumnDefs}
                    addPopupStatus={addPopupStatus}
                    setAddPopupStatus={setAddPopupStatus}
                    initialValues={initialValues}
                    setInitialValues={setInitialValues}
                    modifiedColumnsData={modifiedColumnsData}
                    addPopupWithConfigData={addPopupWithConfigData}
                    isPopupType={isPopupType}
                    setPopupType={setPopupType}
                    remarksList={remarksList}
                    isDeleteButton={true}
                    deletePostback={handleDeletePostback}
                    anAccounts={anAccounts}
                    setIsTriggerUpdate={setIsTriggerUpdate}
                />
            }

            {
                addPopupStatus && <PostbackPopup
                    initialValues={initialValues}
                    setInitialValues={setInitialValues}
                    accountDetails={accountDetails}
                    setAccountDetails={setAccountDetails}
                    remarksList={remarksList}
                    closePopup={closePopup}
                    isPopupType={isPopupType}
                    closeModal={closeModal}
                    anAccounts={anAccounts}
                    setIsTriggerUpdate={setIsTriggerUpdate}
                    setCurrentTemplate={setCurrentTemplate}
                    currentTemplate={currentTemplate}
                    token={token}
                />
            }
            {
                (isOpen && templates.length > 0) && <Modal
                    closeModal={closeModal}
                    templates={templates}
                    setCurrentTemplate={setCurrentTemplate}
                    currentTemplate={currentTemplate}
                    setAddPopupStatus={setAddPopupStatus}
                />
            }

        </div>
    );
}

export default PostbackSetup;